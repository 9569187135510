module.exports = {
    nav: {
        items: [{
                name: "Quiénes somos",
                path: "quienes-somos",
                child: [],
            },
            {
                name: "Proceso",
                path: "proceso",
                child: [],
            },
            {
                name: "Soluciones",
                path: "soluciones",
                child: [
                    {
                        name: "Bleo",
                        path: "soluciones/bleo",
                    },
                    {
                        name: "Ingeniería de Software",
                        path: "soluciones/ingenieria-de-software",
                    },
                    {
                        name: "VentSupport",
                        path: "soluciones/ventsupport",
                    },
                    {
                        name: "IT Staffing",
                        path: "soluciones/it-staffing",
                    },
                    {
                        name: "Servicios de UX/UI",
                        path: "soluciones/servicios-de-uxui",
                    },
                    {
                        name: "Telemedicina",
                        path: "soluciones/telemedicina",
                    },
                    {
                        name: "BImov",
                        path: "soluciones/bimov",
                    },
                    {
                        name: "Metrically",
                        path: "soluciones/metrically",
                    },
                    {
                        name: "LakeIT",
                        path: "soluciones/lakeit",
                    },
                    {
                        name: "QA & Testing",
                        path: "soluciones/qa-&-testing",
                    },
                    {
                        name: "Salesforce",
                        path: "soluciones/salesforce",
                    },
                    {
                        name: "Digital Transformation – SAP",
                        path: "soluciones/digital-transformation-sap",
                    },
                    {
                        name: "Cloud",
                        path: "ccoe",
                    }
                ],
            },
            {
                name: "Presencia",
                path: "presencia",
                child: [],
            },
            {
                name: "Alianzas",
                path: "alianzas",
                child: [],
            },
        ],
    },
    footer: {
        info: {
            items: [
                {
                    title: "Políticas de privacidad",
                    path: "politicas-de-privacidad"
                },{
                    title: "Términos y condiciones",
                    path: "terminos-y-condiciones"
                },
                {
                    title: "Políticas de calidad",
                    path: "politicas-de-calidad"
                }
            ]
        },
        network: {
            items: [
                {
                    title: "Yotube",
                    icon: "FaYoutube",
                    path: "https://www.youtube.com/@globalhitssArgentina",
                },
                {
                    title: "Linkedin",
                    icon: "FaLinkedinIn",
                    path: "https://www.linkedin.com/company/globalhitssargentina",
                },
                // {
                //     title: "Facebook",
                //     icon: "FaFacebookF",
                //     path: "https://www.facebook.com/globalhitssconosur/",
                // },
                // {
                //     title: "Instagram",
                //     icon: "FaInstagram",
                //     path: "https://www.instagram.com/globalhitssconosur/",
                // },
            ],
        },
    },
    home: {
        about: "<p>Global Hitss Argentina somos un equipo de Solucionadores Digitales que te acompañamos en los proyectos que transforman tu negocio.</p>",
        services: {
            title: "Servicios IT",
            items: [{
                    title: "Centro de desarrollo",
                    icon: "MdDeveloperBoard",
                    excerp: "Contamos con la capacidad y la experiencia para desarrollar sistemas de vanguardia a medida.",
                    path: "/soluciones/centro-de-desarrollo",
                },
                {
                    title: "Test LAB",
                    icon: "MdAssignment",
                    excerp: "Nos enfocamos en asegurar el buen funcionamiento y performance de cualquier tipo de desarrollo.",
                    path: "/soluciones/test-lab",
                },
                {
                    title: "Cloud",
                    icon: "MdCloudDownload",
                    excerp: "Somos expertos en tecnologías Cloud y ponemos todo nuestro conocimiento al servicio de nuestros clientes.",
                    path: "/soluciones/cloud",
                },
                {
                    title: "Big Data & Analytics",
                    icon: "MdAssessment",
                    excerp: "Conocemos el desafío de darle uso y vida a los datos para transformarlos en información administrable y útil.",
                    path: "/soluciones/bigdata-analitycs",
                },
            ],
        },
        products: {
            items: [{
                    title: "Bleo",
                    image: "bleo-home.png",
                    excerp: "<p>Una plataforma con Inteligencia Artificial para lograr la eficiencia energética de empresas, reducir su impacto medioambiental y optimizar costos por consumo de energía. ¿Cómo estás usando tu energía?</p>",
                },
                {
                    title: "VentSupport",
                    image: "ventsupport-home.png",
                    excerp: "<p>Soporte con algoritmos de Inteligencia Artificial para el monitoreo y la facilitación de la  interpretación clínica de pacientes conectados  a ventiladores mecánicos hospitalarios. Cuida la vida con inteligencia.</p>",
                },
                {
                    title: "Ingeniería de Software",
                    image: "ing-software-home.png",
                    excerp: "<p>Servicio end-to-end para la creación de productos de software y acompañar las iniciativas empresariales con eficacia, eficiencia y empatía. Porque el ingenio empieza por entender.</p>",
                },
                {
                    title: "IT Staffing",
                    image: "it-staffing-home.png",
                    excerp: "<p>Contratación, administración y seguimiento de profesionales IT para cumplir los proyectos y objetivos de la empresa. Para que el desafío sea el proyecto, no encontrar el equipo.</p>",
                },
            ],
        },
    },
    us: {
        title: "Quiénes somos",
        about: "<p>Somos una empresa de soluciones digitales que crea valor agregado para el negocio de nuestros clientes y para la sociedad.</p><p>Lo hacemos con un proceso centrado en las personas, escuchándolos y acompañándolos desde la idea hasta la implementación, end-to-end.</p><p>Con más de 30 años de experiencia en servicios de IT en toda la región, desde 2019 en Global Hitss expandimos los negocios de la compañía en Argentina, Chile, Uruguay y Paraguay y parte de Europa.</p><p>Y desde nuestro centro de desarrollo en Buenos Aires, construímos ideas y diseñamos soluciones que responden a diversas problemáticas, necesidades o inquietudes de quienes buscan transformar y hacer evolucionar sus empresas.</p>",
        proposal: {
            title: "Propuesta de valor",
            items: [{
                    title: "Tenemos la capacidad",
                    icon: "MdDoneAll",
                    excerp: "Contamos con un equipo de profesionales destacado y calificados para llevar adelante cualquier proyecto.",
                },
                {
                    title: "Tenemos el conocimiento",
                    icon: "MdHighlight",
                    excerp: "Manejamos una gran variedad de lenguajes y tecnologías.",
                },
                {
                    title: "Tenemos la experiencia",
                    icon: "MdStar",
                    excerp: "Llevamos más de 30 años desarrollando software para diferentes necesidades.",
                },
                {
                    title: "Tenemos respaldo",
                    icon: "MdLanguage",
                    excerp: "Ser parte de uno de los grupos de empresas más grande de América Latina nos hace confiables.",
                },
            ],
        },
    },
    aliances: {
        title: "Alianzas",
        about: "<p>Contamos con el ecosistema de socios de negocio más importante de la región.</p>",
        allies: {
            items: [{
                    image: "alianzas_egob2.png",
                },
                {
                    image: "alianzas_sap2.png",
                },
                {
                    image: "alianzas_google2.png",
                },
                {
                    image: "alianzas_dell2.png",
                },
                {
                    image: "alianzas_salesforce2.png",
                },
                {
                    image: "alianzas_microsoft2.png",
                },
                {
                    image: "alianza_aws2.png",
                },
                {
                    image: "alianzas_telus2.png",
                },
            ],
        },
    },
    process: {
        title: "Proceso",
        about: "<p>Nuestro proceso y metodología para el diseño de soluciones nos permiten responder a distintas problemáticas e inquietudes.</p>"
    },
    presence: {
        countries: [{
                title: "Argentina",
                slug: "ar",
                items: [{
                    title: "Buenos Aires",
                    adress: "3636 Gobernador Ugarte, Munro Vicente López, Buenos Aires",
                    cp: "",
                    phone: ["(54) 11 5436-8100"],
                }, ],
            },
            {
                title: "Chile",
                slug: "cl",
                items: [{
                    title: "Santiago de Chile",
                    adress: "Av. el Cóndor 820 Huechuraba, Santiago de Chile",
                    cp: "",
                    phone: ["(55-800) 17 1171"],
                }, ],
            },
            {
                title: "México",
                slug: "mx",
                items: [{
                        title: "Ciudad de México",
                        adress: "Lago zurich Nº219, Piso 9, Plaza carso 2, Col. Ampliación Granada, Del. Miguel Hidalgo",
                        cp: "CP. 11529",
                        phone: ["(55) 5282 8990 ext:10000"],
                    },
                    {
                        title: "Guadalajara",
                        adress: "Av. Adolfo López Mateos Sur Nº 2077. Local Z20, Col. Jardines Plaza del Sol Guadalajara, Jalisco.",
                        cp: "CP. 44510",
                        phone: [],
                    },
                    {
                        title: "Monterrey",
                        adress: "Alfonso Reyes 2615, Col, del Paseo Residencial, Edificio Workspace Valle Mty, N.L.",
                        cp: "",
                        phone: [],
                    },
                    {
                        title: "Querétaro",
                        adress: "Epigmenio González 2, Industria del Hierro, 76158 Santiago de Querétaro",
                        cp: "",
                        phone: [],
                    },
                    {
                        title: "Tijuana",
                        adress: "Agua Caliente 11249, Aviación, 22014 Tijuana, B.C.",
                        cp: "",
                        phone: [],
                    },
                ],
            },
            {
                title: "Colombia",
                slug: "co",
                items: [{
                    title: "Bogotá",
                    adress: "Carrera 11 B Nº97 - 89 Bogotá, Colombia",
                    cp: "",
                    phone: ["+57 (1) 7423 366"],
                }, ],
            },
            {
                title: "Brasil",
                slug: "br",
                items: [{
                        title: "Brasilia",
                        adress: "Setor Comercial Sul, Quadra 5, Bloco E, 3º andar Brasilia - DF",
                        cp: "",
                        phone: ["CEP: 70328-900"],
                    },
                    {
                        title: "Campilas",
                        adress: "Estrada Giuseppina Di Napole, 1185 - Polo II de Alta Tecnología Campinas - SP ",
                        cp: "",
                        phone: ["13086-530"],
                    },
                    {
                        title: "Río de janeiro",
                        adress: "Av. Presidente Vargas, 1012 - 8º andar Centro - Río de Janeiro - RJ ",
                        cp: "CEP: 20071-910",
                        phone: ["+55 21 2121 9040", "+55 21 2121 2046"],
                    },
                    {
                        title: "São Paulo",
                        adress: "Rua dos Ingleses, 600 - 14º andar - Bela Vista - São Paulo - SP",
                        cp: "CEP: 01.329-000",
                        phone: [],
                    },
                ],
            },
            {
                title: "Perú",
                slug: "pe",
                items: [{
                    title: "Lima",
                    adress: "Av. República de Panamá #3576, Piso 6 Lima, Perú ",
                    cp: "",
                    phone: ["(55-800) 17 1171"],
                }, ],
            },
            {
                title: "USA",
                slug: "us",
                items: [{
                    title: "Dallas",
                    adress: "1605 LBJ Freeway Suite 200",
                    cp: "75234 Dallas, Texas, EE.UU",
                    phone: ["1 972 362 2280"],
                }, ],
            },
        ],
    },
    termsAndConditions: {
        title: "Términos y Condiciones Generales",
        about: "<p>Los presentes términos y condiciones generales (en adelante, los “Términos y Condiciones”) rigen la relación entre los usuarios del sitio web www.globalhitssconosur.com (en adelante, el “Sitio Web”) y MIXPLAY S.A. (en adelante, “GH”).<br>La utilización del Sitio Web por los Usuarios implica la aceptación de los presentes Términos y Condiciones en todos sus términos.<br>Los Usuarios deberán utilizar el Sitio Web de conformidad con las disposiciones establecidas en estos Términos y Condiciones, con el ordenamiento jurídico vigente en la República Argentina, y según las pautas de conducta impuestas por la moral, las buenas costumbres y el debido respeto a los derechos de terceros.<p/>",
        items: [{
            title: "1. Descripción del Sitio Web",
            description: "<p>1.1. Solo podrán acceder y utilizar el Sitio Web quienes, de acuerdo con la legislación vigente en la República Argentina tengan capacidad legal para contratar (en adelante, los “Usuarios”).<br>1.2.	El acceso al Sitio Web implica que el Usuario declara y garantiza que cumple con el requisito previsto en la presente cláusula, por lo que asume total responsabilidad por cualquier daño y/o perjuicio que pudiera ocasionarse a GH y/o a terceros por la falsedad de tal declaración.<br>1.3.	El Sitio tiene como finalidad que los Usuarios accedan a información institucional sobre GH.<br>1.4.	Para poder utilizar el Sitio Web se requiere conexión a Internet. El libre acceso y gratuidad no comprenden las facilidades de conexión a Internet. En ningún caso GH proveerá a los Usuarios la conectividad necesaria para que puedan acceder al Sitio Web. La conectividad a Internet, necesaria para acceder al Sitio Web, será por exclusiva cuenta y cargo de cada Usuario.<p/>"
        },
        {
            title: "2. Enlaces a otros sitios",
            description: "<p>En el Sitio Web podrán encontrarse enlaces a sitios web de terceros. En tal caso, GH no es responsable del contenido de dichos sitios web. Además, la existencia de un vínculo entre el Sitio Web y cualquier sitio de terceros de ninguna manera implica que GH apruebe el contenido de dicho sitio, o, asimismo, cualquier uso que dicho contenido pueda tener.<p/>"
        },
        {
            title: "3. Protección de Datos Personales",
            description: "<p>3.1. 	GH cumple con lo establecido en la Ley 25.326 y normativa complementaria (en adelante, la “Normativa de Datos Personales”).<br>3.2. 	GH asume las obligaciones descriptas en la Política de Privacidad, la que resulta complementaria de estos Términos y Condiciones y se encuentran a disposición del Usuario en el Sitio Web.<p/>"
        },
        {
            title: "4. Denuncias",
            description: "<p>4.1. 	Si un Usuario considera que algún contenido del Sitio Web viola sus derechos de propiedad intelectual y/o utiliza ilegítimamente alguna marca del Usuario, deberá efectuar la denuncia correspondiente al correo electrónico marketing.conosur@globalhitss.com.<br>4.2. 	GH revisará las denuncias y podrá eliminar cualquier contenido del Sitio Web que pudiera resultar violatorio de derechos de terceros.<p/>"
        },
        {
            title: "5. Responsabilidad",
            description: "<p>5.1. 	GH en ningún caso será responsable por lucro cesante ni por cualquier consecuencia mediata y/o causal, pérdida de datos o información, pérdida de chance, daños punitivos ni de cualquier otra naturaleza.<br>5.2. 	GH no será responsable por ningún mal funcionamiento, imposibilidad de acceso o malas condiciones de uso del Sitio Web debido al uso de equipos inadecuados, interrupciones relacionadas con proveedores de servicio de Internet, la saturación de la red de Internet y/o por cualquier otra razón.<p/>"
        },
        {
            title: "6. Modificaciones al Sitio",
            description: "<p>GH podrá introducir todos los cambios y modificaciones que estime convenientes al Sitio Web, lo que incluye pero no se limita a agregar, alterar, sustituir o suprimir cualquiera de los servicios o contenidos del Sitio Web en todo momento.<p/>"
        },
        {
            title: "7. Prohibiciones",
            description: "<p>7.1.	Queda terminantemente prohibido utilizar el Sitio Web y/o su contenido con el objeto de lesionar derechos de terceros o contravenir el orden jurídico, como así también toda actividad que constituya una práctica ofensiva a la moral, las buenas costumbres y/o que contravenga la normativa que rige la prestación de servicios de salud en la República Argentina.<br>7.2.	El Usuario no podrá reproducir ni comunicar por ningún medio el contenido del Sitio Web.<p/>"
        },
        {
            title: "8. Propiedad Intelectual",
            description: "<p>8.1.	Todo el material existente en el Sitio Web constituye propiedad exclusiva de GH y/o de terceros que han autorizado su distribución a través del Sitio Web. A título meramente enunciativo, se entenderán incluidos las imágenes, fotografías, diseños, gráficos, sonidos, compilaciones de datos, marcas, nombres, títulos, designaciones, signos distintivos, y todo otro material accesible a través del Sitio.<br>8.2.	GH se reserva todos los derechos sobre el Sitio Web y el mencionado material, no cede ni transfiere a favor del Usuario ningún derecho sobre su propiedad intelectual. En consecuencia, su reproducción, distribución, y/o modificación deberá ser expresamente autorizada por GH. Cualquier actividad de recolección automática de información del Sitio Web (incluyendo pero sin limitarse a web scrapping) será considerada una violación a los presentes Términos y Condiciones.<br>8.3.	Los derechos de propiedad intelectual respecto de los criterios de selección y/o disposición del contenido del Sitio Web corresponden exclusivamente a GH, quedando estrictamente prohibido al Usuario utilizar los contenidos, las categorías y/o cualquier información del Sitio Web con otra finalidad distinta de la de participar en el Sitio Web.<p/>"
        },
        {
            title: "9. Operatividad del Sitio",
            description: "<p>9.1.	GH no garantiza la plena operatividad del Sitio Web en forma ininterrumpida.<br>9.2.	GH podrá suspender el acceso al Sitio Web por motivos de mantenimiento o de seguridad en cualquier momento.<p/>"
        },
        {
            title: "10. Legislación aplicable y jurisdicción",
            description: "<p>10.1.	A todos los efectos legales en relación al Sitio Web, será aplicable la legislación vigente en la República Argentina.<br>10.2.	Toda controversia que se suscite entre las partes con relación a estos Términos y Condiciones, su existencia, validez, calificación, interpretación, alcance, cumplimiento o resolución, se resolverá definitivamente por los tribunales ordinarios en lo Comercial con asiento en la Ciudad de Buenos Aires, quedando expresamente excluido cualquier otro fuero.<p/>"
        },
        ]
    },
    qualityPolicies: {
        title: "Política de la calidad",
        about: "<p>En Global Hitss Argentina hemos asumido el compromiso de interpretar las necesidades y expectativas de los clientes, mediante la mejora continua, apostando a la transformación y la evolución de la sociedad digital, y ofreciendo a nuestros clientes soluciones orientadas a obtener su satisfacción, mediante procesos de calidad y el cumplimiento de los requisitos aplicables. A través de esta política, sienta las bases para su dirección estratégica comprometida en:<p/>",
        items: [{
            title: "",
            paragraph: "<p>Implementar y mantener el Sistema de Gestión de la Calidad, que establece nuestra forma de actuar en cuanto a la calidad de nuestro servicio.<p/>"
        },
        {
            title: "",
            paragraph: "<p>Fijar objetivos y metas alineados a producir software confiable adaptado a las necesidades de los clientes.<p/>"
        },
        {
            title: "",
            paragraph: "<p>Fomentar la competencia y formación de nuestro personal, comprometido con la calidad.<p/>"
        },
        {
            title: "",
            paragraph: "<p>Mantener una filosofía de creatividad e innovación y trabajar en la mejora continua del sistema de gestión de la calidad.<p/>"
        },
        {
            title: "",
            paragraph: "<p>Asegurar una correcta Gestión de las Relaciones con las partes interesadas para lograr el éxito sostenido.<p/>"
        },]
    },
    privacyPolicies: {
        title: "Políticas de privacidad",
        about: "<p>A continuación, se expone la política de privacidad del sitio web www.globalhitssconosur.com  (en adelante, el “Sitio Web”), a cargo de MIXPLAY S.A. (en adelante, “GH”), una sociedad constituida y vigente conforme las leyes de la República Argentina, con domicilio en Gdor. Ugarte 3636 piso 1, Munro, Provincia de Buenos Aires.<p/>",
        items: [{
            title: "1. Objeto",
            description: "<p>El objeto de la presente política de privacidad es la protección de los datos personales y la privacidad de quienes interactúen con el Sitio Web, a fin de garantizar su derecho al honor y a la intimidad, como así también el acceso a la información que sobre ellas pudiera registrarse.<p/>",
        },
        {
            title: "2. Definiciones",
            description: "<p>A los fines de esta política de privacidad (en adelante, la “Política de Privacidad”), los términos “datos personales”, “datos sensibles”, “base de datos”, “tratamiento de datos”, “responsable de la base de datos”, “datos informatizados”, titular de los datos”, “usuario de datos” y “disociación de datos” tendrán el significado asignado por el artículo 2 de la Ley de Protección de Datos Personales y Habeas Data Nº 25.326 (en adelante, la “LPDP”). <p/>",
        },
        {
            title: "3. Principios de protección de los datos personales",
            description: "<p>GH cumple con toda la normativa de protección de datos personales y en particular, con la LPDP y su Decreto Reglamentario N° 1558/01 (en adelante, la “Normativa de Protección de Datos Personales”).<br>En particular, GH cumple con todos los principios que surgen de la Normativa de Protección de Datos Personales, que incluyen pero no se limitan a: (i) el principio de calidad, dado que GH únicamente recolecta aquellos datos personales que resultan necesarios y adecuados para el uso del Sitio Web, y lo hace por medios leales y respetando la intimidad de los titulares de dichos datos personales; (ii) el principio de finalidad, dado que GH utiliza los datos personales recabados únicamente para el fin para el que fueron recolectados; (iii) el principio del consentimiento informado, dado que GH recaba el consentimiento de los titulares de los datos personales en la forma prescripta por la LPDP siempre que ello resulta necesario por no encontrarse en alguna de las excepciones de dicha norma; y (iv) los principios de seguridad y confidencialidad de la información, mediante la utilización de tecnología avanzada que permite resguardar adecuadamente –según el estado de la técnica- la confidencialidad y seguridad de la información. Asimismo, para garantizar la seguridad de los datos, GH aplicará los mismos criterios y el mismo grado de diligencia que aplica para resguardar su propia información.<p/>",
        },
        {
            title: "4. Recolección automatizada de datos personales",
            description: "<p>El Sitio Web no recolectará automáticamente datos personales. No obstante, al utilizar el Sitio Web GH podrá obtener, en forma automática, información anonimizada y/o estadística sobre la conducta de navegación del usuario a través de \"cookies\" y/o otra tecnología. Una cookie es una pequeña información enviada por el Sitio Web que se almacena de manera que GH puede rastrear su actividad e interacción en el Sitio Web.<br>La información recolectada automáticamente será utilizada por GH para mejorar la experiencia del usuario en el Sitio Web y/o para obtener estadísticas de usabilidad.<br>El usuario puede rehusarse a aceptar cookies y/u otras tecnologías de recolección automática de datos personales desactivándolo desde su dispositivo, pero existe la posibilidad de que no tenga acceso a ciertos sectores del Sitio Web y/o que el Sitio Web no funcione correctamente.<p/>",
        },
        {
            title: "5. Recolección de datos personales",
            description: "<p>GH únicamente recolectará los datos personales que el usuario del Sitio Web informe al completar el formulario de contacto. El formulario de recolección de datos personales contiene las previsiones exigidas por la LPDP, en particular en lo referido al consentimiento informado.<br>El titular de los datos personales es responsable por la veracidad y exactitud de la información que consigne en ellos, y asume la responsabilidad de mantener actualizada dicha información, en caso de corresponder.<p/>",
        },
        {
            title: "6. Datos sensibles",
            description: "<p>En ningún caso GH solicitará y/o recolectará automáticamente datos sensibles del usuario del Sitio Web.<p/>",
        },
        {
            title: "7. Ejercicio de los derechos de acceso, actualización rectificación, supresión o confidencialidad",
            description: "<p>A fin de ejercer los derechos de acceso, actualización, rectificación, supresión o confidencialidad respecto de sus datos personales, los usuarios del Sitio Web deberán enviar un correo electrónico a marketing.conosur@globalhitss.com, indicando el motivo del contacto y acreditando fehacientemente su identidad.<br>Una vez verificada la identidad del titular de los datos personales, GH permitirá el acceso y/o el ejercicio de los demás derechos, en forma gratuita a intervalos no menores de seis meses, salvo que acredite un interés legítimo al efecto, y en los términos previstos en la LPDP.<p/>",
        },
        {
            title: "8. Plazo de conservación",
            description: "<p>GH conservará los datos personales de los usuarios del Sitio Web que completen el formulario por el plazo de quince (15) días posteriores a la recepción de la consulta. Luego eliminará toda la información.<p/>",
        },
        {
            title: "9. Modificaciones a la Política de Privacidad",
            description: "<p>Esta Política de Privacidad podrá ser modificada por GH a fin de adecuarla a las disposiciones legales y reglamentarias vigentes.  Las modificaciones se publicarán en el Sitio Web y entrarán en vigencia el mismo día de su publicación, salvo que dispongan lo contrario.<p/>",
        },
        {
            title: "10. Órgano de control ",
            description: "<p>La Agencia de Acceso a la Información Pública, en su carácter de Órgano de Control de la Ley N° 25.326, tiene la atribución de atender las denuncias y reclamos que interpongan quienes resulten afectados en sus derechos por incumplimiento de las normas vigentes en materia de protección de datos personales.<p/>",
        },]
    },
    agradecimiento: {
        title: "El formulario se envió correctamente.",
        description: "Gracias por el contacto."
    },
}
